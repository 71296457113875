import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';

import { AnimationOptions } from 'ngx-lottie';
import { trigger, state, style, animate, transition } from '@angular/animations';


@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent {
}
