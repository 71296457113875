<div class="mb-2 d-flex justify-content-start align-items-start mt-3">
  <p-checkbox class="me-2" [(ngModel)]="cgu1" value="1" inputId="cgu1" />
  <label for="cgu1">
    Je certifie l’exactitude des informations transmises et reconnais avoir pris connaissance et accepté les informations générales d’utilisation.
  </label>
</div>
<div class="mb-2 d-flex justify-content-start align-items-start mb-5">
  <p-checkbox class="me-2" [(ngModel)]="cgu2" value="1" inputId="cgu2" />
  <label for="cgu2">
    J’ai téléchargé les conditions générales de vente lien cliquable des CGU
  </label>
</div>
