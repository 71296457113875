import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormIdComponent } from './pages/identification/form-id/form-id.component';
import { LoginComponent } from './pages/login/login.component';
import { MentionsLegalesComponent } from './pages/mentions-legales/mentions-legales.component';
import { OnboardingComponent } from './pages/onboarding/onboarding.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { IdentificationComponent } from './pages/identification/identification.component';
import { ScanQrcodeComponent } from './pages/identification/scan-qrcode/scan-qrcode.component';

const routes: Routes = [
  {
    path: '',
    component: WelcomeComponent
  },
  {
    path: 'identification',
    component: IdentificationComponent
  },
  {
    path: 'scaning-qrcode',
    component: ScanQrcodeComponent
  },
  {
    path: 'onboarding',
    component: OnboardingComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'mentions-legales',
    component: MentionsLegalesComponent
  },
  {
    path: ':token',
    component: WelcomeComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
