import { Component, OnInit } from '@angular/core';
import { ScannerQRCodeConfig } from 'ngx-scanner-qrcode';
import { CustomerFileService } from '../../../shared/services/model/customerFile/customer-file.service';
import { Router } from '@angular/router';
import { ToolsService } from '../../../shared/services/tools/tools.service';

@Component({
  selector: 'app-scan-qrcode',
  templateUrl: './scan-qrcode.component.html',
  styleUrl: './scan-qrcode.component.scss'
})
export class ScanQrcodeComponent implements OnInit {

  public config: ScannerQRCodeConfig;
  step = 1;

  constructor(
    private customerFileService: CustomerFileService,
    private router: Router,
    private _toolsService: ToolsService
  ) { }

  ngOnInit(): void {
    this.config = {
      constraints: {
        video: {
          width: window.innerWidth,
          ...(this._toolsService.checkIfMobile() && { facingMode: { exact: 'environment' } })
        }
      },
      isBeep: false
    };
  }

  onScanSuccess($event) {
    console.log($event);
    const url = new URL($event[0].value);
    const pathname = url.pathname;
    const token = pathname.substring(1);
    localStorage.setItem('token', token);
    const currentCustomer = this.customerFileService.customerFile;

    this.customerFileService.initCustomerFile();
    this.router.navigate(['identification']);
  }
}
