import { Injectable } from '@angular/core';
import { errorSignal } from '../../signals/error.signal';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor() { }

  show() {
    errorSignal.set(true); // Met à jour le Signal pour indiquer que le chargement a commencé
  }

  hide() {
    errorSignal.set(false); // Met à jour le Signal pour indiquer que le chargement est terminé
  }

  status() {
    return errorSignal(); // Permet aux composants de s'abonner au Signal
  }
}
