import { Injectable } from '@angular/core';
import { loadingSignal } from '../../signals/loading.signal';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  constructor() { }

  show() {
    loadingSignal.set(true); // Met à jour le Signal pour indiquer que le chargement a commencé
  }

  hide() {
    loadingSignal.set(false); // Met à jour le Signal pour indiquer que le chargement est terminé
  }

  loading() {
    return loadingSignal(); // Permet aux composants de s'abonner au Signal
  }
}
