import { Component, NgZone, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';

import { AnimationOptions } from 'ngx-lottie';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Router } from '@angular/router';

import { ActivatedRoute } from '@angular/router';
import { CustomerFileService } from '../../shared/services/model/customerFile/customer-file.service';


@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.scss',

  animations: [
    // Animation pour le fadeOut
    trigger('fadeOut', [
      state('visible', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0 })),
      transition('visible => hidden', [
        animate('1s ease-out')
      ])
    ]),
    // Animation pour le slideFromLeft
    trigger('slideFromLeft', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)', opacity: 0 }),
        animate('0.5s ease-out', style({ transform: 'translateX(0)', opacity: 1 }))
      ]),
      transition(':leave', [
        animate('0.5s ease-out', style({ transform: 'translateX(-100%)', opacity: 0 }))
      ])
    ])
  ]
})
export class WelcomeComponent implements OnInit {

  private animationItem: AnimationItem;
  done: boolean = false;  // Variable pour contrôler l'exécution de la fin du playSegment2
  showLanguage: boolean = false;

  // Set the path to the lottie file.
  options: AnimationOptions = {
    path: 'assets/animations/vini-loading.json',
    loop: false,
    autoplay: false,
  };

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public customerFileService: CustomerFileService
  ) {
  }

  ngOnInit(): void {
    this.showLanguage = false;
    this.activatedRoute.paramMap.subscribe(params => {
      const token = params.get('token');
      if(token && (token.length == 32)){
        localStorage.setItem('token', token);
      } else {
        localStorage.removeItem('token');
      }
      this.customerFileService.initCustomerFile();
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.ngZone.run(() => {
        this.showLanguage = true;
      });
    }, 5000);
  }

  // Capture l'instance de l'animation Lottie
  onAnimationCreated(animationItem: AnimationItem): void {
    this.animationItem = animationItem;

    // Utiliser DOMLoaded pour s'assurer que l'animation est prête
    this.animationItem.addEventListener('DOMLoaded', () => {
      this.playSegment1();  // Appeler le segment 1 après le chargement de l'animation
    });
  }

  // Segment 1 : Vol de gauche vers le centre (frames 0 à 30)
  playSegment1(): void {
    console.log('Playing Segment 1');
    this.animationItem.playSegments([0, 60], true);  // Jouer frames 0 à 30

    // Ajouter un écouteur pour passer au segment 2 à la fin de Segment 1
    this.animationItem.addEventListener('complete', () => {
      if (!this.done) {
        console.log('Segment 1 complete. Starting Segment 2');
        this.playSegment2();  // Appeler le segment 2 une fois que Segment 1 est terminé
      }
    });
  }

  // Segment 2 : Oiseau bat des ailes au centre (frames 30 à 110)
  playSegment2(): void {
    console.log('Playing Segment 2');
    this.animationItem.playSegments([60, 120], true);  // Jouer frames 30 à 110

    // Boucler sur le segment 2 tant que done n'est pas à true
    this.animationItem.addEventListener('complete', () => {
      if (!this.done) {
        console.log('Looping Segment 2');
        // this.playSegment2();  // Rejouer le Segment 2 jusqu'à ce que done soit true
      } else {
        console.log('Segment 2 done. Starting Segment 3');
        this.playSegment3();  // Passer au Segment 3 si done est true
      }
    });
  }

  playSegment3(): void {
    console.log('Playing Segment 3');

    // Jouer frames 120 à 150 manuellement, sans utiliser loop
    this.animationItem.playSegments([120, 150], true);

    // Calculer la durée du segment en fonction du nombre de frames
    const segmentDuration = (150 - 121) * (1000 / this.animationItem.frameRate); // Durée en millisecondes

    // Utiliser setTimeout pour arrêter l'animation à la fin du segment
    setTimeout(() => {
      console.log('Stopping animation at frame 150');
      this.animationItem.goToAndStop(150, true);  // Forcer l'arrêt à la frame 150
      this.router.navigate(['/onboarding']);
    }, segmentDuration);
  }

  onLanguageSelected(lang: string) {
    console.log('State of showLanguage after language selection:', this.showLanguage);  // Affiche false
    this.done = true;
  }
}
