import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SidebarService } from '../../shared/services/sidebar/sidebar.service';
import { AnimationOptions } from 'ngx-lottie';
import { Router } from '@angular/router';
import { ConsoleService } from '../../shared/services/console/console.service';
import { SettingService } from '../../shared/services/model/setting/setting.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NotificationService } from '../../shared/services/notification/notification.service';

@Component({
  selector: 'app-identification',
  templateUrl: './identification.component.html',
  styleUrl: './identification.component.scss'
})
export class IdentificationComponent implements OnInit, AfterViewInit {

  active: number = 0;
  sidebarVisible: boolean = false;
  options: AnimationOptions = {
    path: 'assets/animations/vini-flying.json'
  };

  @ViewChild('firstStepper') firstStepper!: ElementRef;
  @ViewChild('secondStepper') secondStepper!: ElementRef;
  @ViewChild('thirdStepper') thirdStepper!: ElementRef;
  stepperPosition: number = 12;
  stepperWidth: number = 100;

  iframeUrl: SafeUrl;

  idenficationIsDone: boolean = false;

  constructor(
    public sidebarService: SidebarService,
    private router: Router,
    private _consoleService: ConsoleService,
    private _settingService: SettingService,
    private _sanitizer: DomSanitizer,
    private _notificationService: NotificationService,
  ){}

  ngOnInit(): void {
    const lang = localStorage.getItem('language');
    this._consoleService.debug('IdenficationComponent - ngOnInit', 'b', 'lang', lang);
    if(!lang){
      this.router.navigate(['/']);
    }
  }

  ngAfterViewInit() {
    // localStorage.clear();

    setTimeout(() => {
      if (this.firstStepper) {
        const target = this.firstStepper.nativeElement;
        this.moveSliderTabs(target);
      }
    }, 0);
  }

  getStepperPosition(stepper: number, event) {
    this._consoleService.debug('StepperService - getStepperPosition', 'b', 'stepper', stepper);
    this._consoleService.debug('StepperService - getStepperPosition', 'b', 'localStorage.getItem("iframeUrl")', localStorage.getItem('iframeUrl'));
    if(stepper == 1){
      this.showNetheosFrame();
      return;
    } else if(stepper == 2){
      this.showClickAndWrapForm();
      return;
    }
    this.active = stepper;

    const target = event.currentTarget as HTMLElement;
    this.moveSliderTabs(target);
  }

  moveSliderTabs(target){
    const rect = target.getBoundingClientRect();

    // Supposons que vous voulez la position relative par rapport à un parent
    const parent = target.parentElement;
    const parentRect = parent?.getBoundingClientRect();

    if (parentRect) {
      this.stepperPosition = rect.left - parentRect.left;
      this.stepperWidth = rect.width;
    } else {
      this.stepperPosition = rect.left;
    }

    document.getElementById('slider').style.width = this.stepperWidth + 'px';
    document.getElementById('slider').style.left = this.stepperPosition + 'px';
  }

  goToWelcome(){
    this.router.navigate(['/']);
  }

  showNetheosFrame(){
    const url = localStorage.getItem('iframeUrl');
    this._consoleService.debug('IdentificationComponent - showNetheosFrame', 'o', 'url', url);
    if(!url){
      this._notificationService.showNotification('Oops', 'Veuillez confirmer vos informations personnelles pour garantir leur exactitude et assurer la sécurité de votre compte.');
      return;
    }
    this.iframeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(url);
    this.active = 1;
    const target = this.secondStepper.nativeElement;
    this.moveSliderTabs(target);
  }

  showClickAndWrapForm() {
    const storedValue = localStorage.getItem('idenficationIsDone');
    this.idenficationIsDone = storedValue !== null ? storedValue === 'true' : false;
    if(!this.idenficationIsDone){
      this._notificationService.showNotification('Oops', 'Veuillez valider votre identité pour garantir la sécurité de vos informations.');
      return;
    }
    this.active = 2;
    const target = this.thirdStepper.nativeElement;
    this.moveSliderTabs(target);
  }

  showLanguageForm = false;
}
