<div class="" style="height: 100dvh; width: 100dvw; position: absolute; top: 0; left: 0; background-color: #F6F5F9; overflow:hidden;">
  <header class="header d-flex align-items-end justify-content-center text-center pb-5 position-relative" style="z-index: 99;">
    <a [routerLink]="'/'"><img src="assets/images/logo_vini.png" class="logo-vini" alt="Logo Vini" style="width: 50px; position: fixed; top: 15px; left: 15px;"></a>
    <!-- <div (touc   -->
    <button class="btn bg-none position-fixed" style="top: 15px; right: 15px;" (click)="sidebarService.show()"><i class="pi pi-bars fs-2 text-white"></i></button>
    <div #steppers class="steppers rounded-pill d-flex justify-content-between align-items-center shadow slideInTopAnimation" [class.active-1]="active == 0" [class.active-2]="active == 1" [class.active-3]="active == 2">
      <div #firstStepper class="stepper" [class.active]="active == 0" (click)="getStepperPosition(0, $event)">
        {{ 'step_1_title' | translate }}
      </div>
      <div #secondStepper class="stepper" [class.active]="active == 1" (click)="getStepperPosition(1, $event)">
        {{ 'step_2_title' | translate }}
      </div>
      <div #thirdStepper class="stepper" [class.active]="active == 2" (click)="getStepperPosition(2, $event)">
        {{ 'step_3_title' | translate }}
      </div>
      <span id="slider" class="slider"></span>
    </div>
  </header>
  <section class="identificationContent">
    <app-form-id *ngIf="!showLanguageForm && active == 0" (showNetheosFrame)="showNetheosFrame()"></app-form-id>
      <!-- Netheos Iframe -->
    <div id="netheosIframe" *ngIf="!showLanguageForm && active == 1 && iframeUrl">
      <iframe #myIframe [src]="iframeUrl" width="100%" height="100%" allow="camera; microphone"></iframe>
    </div>
    <div id="clickWrapFrame" *ngIf="!showLanguageForm && active == 2 && idenficationIsDone">
      <app-namirial-click-wrap></app-namirial-click-wrap>
    </div>
    <div id="languageFrame" *ngIf="showLanguageForm">
      <app-language (languageSelected)="showLanguageForm = false" class="text-dark"></app-language>
    </div>
  </section>
</div>

<app-sidebar (showLanguageForm)="showLanguageForm = true"></app-sidebar>
<app-notification></app-notification>
