<div class="fullscreen d-flex align-items-center justify-content-center bg-vini-primary">
  <div class="close bg-white rounded-circle" [routerLink]="'/identification'">
    <p-button icon="pi pi-times" severity="primary" [text]="true" [plain]="true" [style]="{color: '#dc001c'}" />
  </div>
  <div class="container" style="max-width: 400px;">
    <ngx-scanner-qrcode
      #action="scanner"
      [config]="config"
      (event)="onScanSuccess($event)"
      [hidden]="step < 4">
    </ngx-scanner-qrcode>
    <a class="btn btn-outline-light btn-lg text-white rounded-pill w-100 mt-5 slideInBottomAnimation" [style.animationDelay]="'1.8s'" [hidden]="step < 4" (click)="action.stop()" [routerLink]="'/identification'">
      {{ 'Annuler' | translate }}
    </a>

    <div [hidden]="step > 3">

      <div id="step1" class="step" *ngIf="step == 1">
        <div class="step-title text-white">
          <span class="number">1</span> {{ 'Munissez vous de votre pochette' | translate }}
        </div>
        <img src="assets/images/scan-helper-1.png" alt="scan-helper" width="100%" class="rounded">
        <a class="btn btn-outline-light btn-lg text-white rounded-pill w-100 mt-5 slideInBottomAnimation" [style.animationDelay]="'1.8s'" (click)="step = 2;">
          {{ 'Continuer' | translate }}
        </a>
      </div>

      <div id="step2" class="step" *ngIf="step == 2">
        <div class="step-title text-white">
          <span class="number">2</span>{{ 'Trouvez le QR Code' | translate }}
        </div>
        <img src="assets/images/scan-helper-2.png" alt="scan-helper" width="100%" class="rounded">
        <a class="btn btn-outline-light btn-lg text-white rounded-pill w-100 mt-5 slideInBottomAnimation" [style.animationDelay]="'1.8s'" (click)="step = 3;">
          {{ 'Continuer' | translate }}
        </a>
      </div>

      <div id="step3" class="step" *ngIf="step == 3">
        <div class="step-title text-white">
          <span class="number">3</span>{{ 'Cliquez sur Scanner et scannez le QR Code' | translate }}
        </div>
        <img src="assets/images/scan-helper-3.png" alt="scan-helper" width="100%" class="rounded">
        <a class="btn btn-outline-light btn-lg text-white rounded-pill w-100 mt-5 slideInBottomAnimation" [style.animationDelay]="'1.8s'" (click)="step = 4; action.start()">
          {{ 'Scanner' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
