import { AfterViewInit, Component, OnInit } from '@angular/core';
declare var clickWrap: any;

@Component({
  selector: 'app-namirial-click-wrap',
  templateUrl: './namirial-click-wrap.component.html',
  styleUrls: ['./namirial-click-wrap.component.scss']
})
export class NamirialClickWrapComponent implements AfterViewInit {

  cgu1 = false;
  cgu2 = false;

  constructor() { }

  ngAfterViewInit(): void {
    // this.loadClickWrap();
  }
}
