<div style="height: 100dvh; width: 100dvw; position: absolute; top: 0; left: 0;">
  <div class="d-flex align-items-center justify-content-center flex-column text-center bg-vini-primary" style="height: 100dvh;;">
    <div class="animation-container" style="margin-top: -140px;">
      <ng-lottie [options]="options" class="lottie-animation" (animationCreated)="onAnimationCreated($event)"></ng-lottie>
    </div>
    <div style="margin-top: -65px;">
      <div *ngIf="!showLanguage; else languageComponent" @fadeOut>
        <div class="welcomeText">
          <span style="font-size: 3.5rem; color: #FFF; margin-top: 40px; font-weight: 400;">
              Bienvenue
          </span><br/>
          <span style="font-size: 1.6rem; color: #FFF; margin-top: 20px; font-weight: 400;">
              sur le plus grand réseau 4G+ <br> de Polynésie Française
          </span>
        </div>
      </div>
      <ng-template #languageComponent>
        @if(customerFileService.customerFile.esim){
          <div class="welcomeText mt-5">
            <span style="font-size: 1.6rem; color: #FFF; margin-top: 20px; font-weight: 400;">
                Votre identification<br/>est déjà validé.<br/>
                Nous vous remercions de votre confiance
            </span>
          </div>
        } @else {
          <div id="languageComponent">
            <app-language (languageSelected)="onLanguageSelected($event)" class="text-white"></app-language>
          </div>
        }
      </ng-template>
    </div>
  </div>
</div>
