import { Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  title: string;
  content: string;
  show: boolean = false;

  constructor() { }

  showNotification(title: string, content: string) {
    this.title = title;
    this.content = content;
    this.show = true;
  }

  hideNotification() {
    this.show = false;
  }

  isShow() {
    return this.show;
  }

  setDefaultNotification() {
    this.title = 'Oups';
    this.content = 'Il semblerait qu\'une erreur soit survenue. Veuillez réessayer plus tard.';
  }
}
