import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {

  constructor() { }

  checkIfMobile() {
    const userAgent = navigator.userAgent || navigator.vendor || window['opera'];

    // Combiner la vérification de la taille de l'écran et du userAgent
    if (/android|iPhone|iPad|iPod/i.test(userAgent) || window.innerWidth <= 768) {
      return true;
    }

    return false;
  }
}
