<div class="p-4">
  <!-- Customer Form -->
  <form [formGroup]="customerForm" id="customerForm" *ngIf="!iframeUrl && !otpRequest" (ngSubmit)="updateCustomer()">
    <!-- Phone Number -->
    <div class="mb-2 position-relative slideInLeftAnimation" [style.animationDelay]="'0.8s'">
      <div style="position: absolute; right: 0px; top: 0px;">
        <button type="button" class="btn bg-vini-primary rounded-pill text-white btn-sm" [routerLink]="['/scaning-qrcode']">
          <p-inputIcon styleClass="pi pi-qrcode"></p-inputIcon> {{ 'Scanner' | translate }}
        </button>
      </div>
      <label class="mb-3" for="phone">
        {{ 'N° Mobile prépayé' | translate }} <span class="mandatory">*</span>
      </label>
      <div *ngIf="showPhoneField; else phoneDisplay">
        <input pInputText id="phone" class="form-control p-2" formControlName="phone" [ngClass]="{ 'is-invalid': isFieldInvalid('phone') }" />
        <!-- Error Message -->
        <div *ngIf="isFieldInvalid('phone')" class="text-danger">
          <small *ngIf="customerForm.get('phone').errors?.['required']">
            {{ 'Le numéro de téléphone est requis' | translate }}
          </small>
          <small *ngIf="customerForm.get('phone').errors?.['pattern']">
            {{ 'Le numéro de téléphone est invalide' | translate }}
          </small>
        </div>
        <label class="mb-3" style="font-size: 12px;">
          {{ 'Retrouvez votre numéro de téléphone dans le SMS de bienvenue reçu lors de l\'activation de votre SIM' | translate }}
        </label>
      </div>
      <ng-template #phoneDisplay>
        <div class="form-control px-2 py-3 bg-light">
          {{ customerFileService.customerFile.phone }}
        </div>
      </ng-template>
    </div>

    <!-- Civility -->
    <div class="mb-2 slideInLeftAnimation" [style.animationDelay]="'1s'" style="position: relative; z-index: 9;">
      <label class="mb-3" for="civility">
        {{ 'Civilité' | translate }} <span class="mandatory">*</span>
      </label>
      <p-dropdown id="civility" [options]="genres" formControlName="civility" optionLabel="name" optionValue="value" [ngClass]="{ 'is-invalid': isFieldInvalid('civility') }"></p-dropdown>
      <!-- Error Message -->
      <div *ngIf="isFieldInvalid('civility')" class="text-danger">
        <small *ngIf="customerForm.get('civility').errors?.['required']">
          {{ 'La civilité est requise' | translate }}
        </small>
      </div>
    </div>

    <!-- Last Name -->
    <div class="mb-2 slideInLeftAnimation" [style.animationDelay]="'1.2s'">
      <label class="mb-3" for="lastname">
        {{ 'Nom' | translate }} <span class="mandatory">*</span>
      </label>
      <input pInputText id="lastname" class="form-control p-2" formControlName="lastname" [ngClass]="{ 'is-invalid': isFieldInvalid('lastname') }" />
      <!-- Error Message -->
      <div *ngIf="isFieldInvalid('lastname')" class="text-danger">
        <small *ngIf="customerForm.get('lastname').errors?.['required']">
          {{ 'Le nom est requis' | translate }}
        </small>
      </div>
    </div>

    <!-- First Name -->
    <div class="mb-2 slideInLeftAnimation" [style.animationDelay]="'1.4s'">
      <label class="mb-3" for="firstname">
        {{ 'Prénom' | translate }} <span class="mandatory">*</span>
      </label>
      <input pInputText id="firstname" class="form-control p-2" formControlName="firstname" [ngClass]="{ 'is-invalid': isFieldInvalid('firstname') }" />
      <!-- Error Message -->
      <div *ngIf="isFieldInvalid('firstname')" class="text-danger">
        <small *ngIf="customerForm.get('firstname').errors?.['required']">
          {{ 'Le prénom est requis' | translate }}
        </small>
      </div>
    </div>

    <!-- Birthdate -->
    <div class="mb-2 slideInLeftAnimation" [style.animationDelay]="'1.6s'" style="position: relative; z-index: 9;">
      <label class="mb-3" for="birthdate">
        {{ 'Date de naissance JJ/MM/AAAA' | translate }}
        <span class="mandatory">*</span>
      </label>
      <p-calendar formControlName="birthdate" [iconDisplay]="'input'" [showOnFocus]="false" [showIcon]="true" dateFormat="dd/mm/yy" dataType="string" [minDate]="minDate" [maxDate]="maxDate" [defaultDate]="maxDate" [ngClass]="{ 'is-invalid': isFieldInvalid('birthdate') }"></p-calendar>
      <!-- Error Message -->
      <div *ngIf="isFieldInvalid('birthdate')" class="text-danger">
        <small *ngIf="customerForm.get('birthdate').errors?.['required']">
          {{ 'La date de naissance est requise' | translate }}
        </small>
        <small *ngIf="customerForm.get('birthdate').errors?.['ageInvalid']">
          {{ 'Vous devez avoir au moins 18 ans' | translate }}
        </small>
      </div>
    </div>

    <!-- Submit Button -->
    <button type="submit" class="btn btn-vini-primary btn-lg text-white shadow rounded-pill w-100 mt-3 slideInBottomAnimation" [style.animationDelay]="'1.8s'">
      {{ 'Valider' | translate }}
    </button>
  </form>

  <!-- OTP Form -->
  <ng-container *ngIf="otpRequest">
    <form [formGroup]="otpForm" id="otpForm">
      <div class="d-flex flex-column align-items-center">
        <p class="text-color-secondary block my-5">
          {{ 'Veuillez saisir le code reçu par sms.' | translate }}
        </p>
        <p-inputOtp formControlName="otp_code" [length]="6" style="gap: 0" [ngClass]="{ 'is-invalid': isFieldInvalid('otp_code', otpForm) }">
          <ng-template pTemplate="input" let-token let-events="events" let-index="index">
            <input pInputText type="text" [maxLength]="1" (input)="events.input($event)" (keydown)="events.keydown($event)" [attr.value]="token" class="custom-otp-input" />
            <div *ngIf="index === 3" class="px-3">
              <i class="pi pi-minus"></i>
            </div>
          </ng-template>
        </p-inputOtp>
        <!-- Error Message -->
        <div *ngIf="isFieldInvalid('otp_code', otpForm)" class="text-danger">
          <small *ngIf="otpForm.get('otp_code').errors?.['required']">
            {{ 'Le code OTP est requis' | translate }}
          </small>
          <small *ngIf="otpForm.get('otp_code').errors?.['pattern']">
            {{ 'Le code OTP est invalide' | translate }}
          </small>
        </div>
        <div *ngIf="countdownTime > 0" class="d-flex justify-content-center mt-5 text-center">
          {{ 'Le code est valable pour' | translate }}<br/>
          {{ countdownDisplay }}
        </div>
        <div class="d-flex justify-content-center mt-5">
          <button *ngIf="countdownTime <= 0" class="btn bg-vini-secondary text-white me-3" type="button" (click)="resendOtp()">
            {{ 'Renvoyer le code' | translate }}
          </button>
          <button class="btn btn-vini-primary" type="button" (click)="validateOtp()">
            {{ 'Valider' | translate }}
          </button>
        </div>
      </div>
    </form>
  </ng-container>


</div>
