import { Injectable } from '@angular/core';
import { ApiService } from '../../api/api.service';
import { ConsoleService } from '../../console/console.service';
import { CustomerFile } from '../../../interfaces/customer-file';

@Injectable({
  providedIn: 'root'
})
export class CustomerFileService {

  customerFile: CustomerFile;

  constructor(
    private _apiService: ApiService,
    private _consoleService: ConsoleService,
  ) { }

  setCustomerFile(customer: CustomerFile) {
    this.customerFile = customer;
  }

  initCustomerFile() {
    this.customerFile = {
      customerId:0,
      customerFileId:0,
      phone:"",
      civility:"",
      lastname:"",
      firstname:"",
      birthdate:"",
      otp_code:"",
      language:"",
      esim:false
    };
    const token = localStorage.getItem('token');
    this._consoleService.debug('CustomerFileService - initCustomerFile', 'b', 'token', token);
    if(token && (token.length == 32)){
      this.getCustomerFileByToken(token);
    }
  }

  getCustomerFileByToken(token) {
    this._apiService.get('customer-files/token/' + token).subscribe((data: any) =>{

        //Format birtgdate
        if(data.data.birthdate){
          const [datePart] = data.data.birthdate.split('T');
          const [year, month, day] = datePart.split('-');

          data.data.birthdate = `${day}/${month}/${year}`;
        }
        this.setCustomerFile(data.data);
    });
  }
}
