import { Component, EventEmitter, OnInit, Output, Renderer2 } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { ApiService } from '../../../shared/services/api/api.service';
import { ConsoleService } from '../../../shared/services/console/console.service';
import { LoadingService } from '../../../shared/services/loading/loading.service';
import { Subscription } from 'rxjs';

import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { CustomerFile } from '../../../shared/interfaces/customer-file';
import { CustomerFileService } from '../../../shared/services/model/customerFile/customer-file.service';

import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { SettingService } from '../../../shared/services/model/setting/setting.service';

interface Genre {
  name: string;
  value: string;
}

@Component({
  selector: 'app-form-id',
  templateUrl: './form-id.component.html',
  styleUrls: ['./form-id.component.scss'],
  providers: [MessageService],
})
export class FormIdComponent implements OnInit {
  items: MenuItem[] | undefined;

  customer: CustomerFile | undefined;

  showPhoneField = true;

  genres: Genre[] | undefined;
  selectedGenre: Genre | undefined;
  birthday: Date | undefined;
  isRevendeur = false;
  iframeUrl: any;

  otpRequest: boolean = false;
  languages = [
    { name: '🇫🇷', code: 'fr' },
    { name: '🇬🇧', code: 'en' },
    { name: '🇵🇫', code: 'pf' },
  ];

  token: string = '';
  showConsent: boolean = false;

  private subscription: Subscription;
  minDate;
  maxDate;

  countdownTime: number; // 5 minutes in seconds
  countdownDisplay: string = '00:00:00';
  private intervalId: any;

  startQrCodeScanner: boolean = false;

  // Reactive Forms
  customerForm: FormGroup;
  otpForm: FormGroup;

  @Output() updateActiveTabs = new EventEmitter<number>();
  @Output() showNetheosFrame = new EventEmitter<boolean>();

  constructor(
    private fb: FormBuilder,
    private _loadingService: LoadingService,
    private _apiService: ApiService,
    private _consoleService: ConsoleService,
    private _sanitizer: DomSanitizer,
    private _messageService: MessageService,
    public translateService: TranslateService,
    private renderer: Renderer2,
    public customerFileService: CustomerFileService,
    private _settingService: SettingService
  ) {

  }

  ngOnInit() {
    const token = localStorage.getItem('token');

    if (token && token.length == 32) {
      this.token = token;
      this.showPhoneField = false;
    } else {
      this.showPhoneField = true;
    }

    this.translateService.get(['Monsieur', 'Madame']).subscribe(translations => {
      this.genres = [
        { name: translations['Monsieur'], value: 'M.' },
        { name: translations['Madame'], value: 'Mme' },
      ];
    });

    this.customerFileService.customerFile.language = this.translateService.currentLang;
    const today = new Date();
    this.minDate = new Date( today.getFullYear() - 100, today.getMonth(), today.getDate() ); // 100 years back
    this.maxDate = new Date( today.getFullYear() - 18, today.getMonth(), today.getDate() ); // 18 years back

    // Initialize Reactive Forms
    this.initForms();
  }

  initForms() {
    this.customerForm = this.fb.group({
      phone: [
        this.customerFileService.customerFile.phone,
        [
          Validators.required,
          Validators.pattern(/^(?:\+689|689)?87[0-9]{6}$/), // Adjust pattern as needed
        ],
      ],
      civility: [
        this.customerFileService.customerFile.civility,
        Validators.required,
      ],
      lastname: [
        this.customerFileService.customerFile.lastname,
        Validators.required,
      ],
      firstname: [
        this.customerFileService.customerFile.firstname,
        Validators.required,
      ],
      birthdate: [
        this.customerFileService.customerFile.birthdate,
        [Validators.required, ageValidator(18)],
      ],
    });

    this.otpForm = this.fb.group({
      otp_code: ['', [Validators.required, Validators.pattern(/^[0-9]{6}$/)]],
    });
  }

  prepareCustomerDataForRequest(): CustomerFile {
    const formValues = this.customerForm.value;

    let birthdateFormatted = '';

    if (formValues.birthdate) {
      this._consoleService.debug('CustomerFileService - prepareCustomerDataForRequest', 'b', 'formValues.birthdate', formValues.birthdate);
      const birthdate = formValues.birthdate.split('/');

      this._consoleService.debug('CustomerFileService - prepareCustomerDataForRequest', 'b', 'birthdate', birthdate);
      // const day = String(birthdate.getDate()).padStart(2, '0');
      // const month = String(birthdate.getMonth() + 1).padStart(2, '0');
      // const year = birthdate.getFullYear();
      birthdateFormatted = `${birthdate[2]}-${birthdate[1]}-${birthdate[0]}`;
    }

    return {
      customerId: this.customerFileService.customerFile.customerId,
      customerFileId: this.customerFileService.customerFile.customerFileId,
      phone: formValues.phone,
      civility: formValues.civility,
      lastname: formValues.lastname,
      firstname: formValues.firstname,
      birthdate: birthdateFormatted,
      language: this.customerFileService.customerFile.language,
    };
  }

  updateCustomer() {
    if (this.showPhoneField) {
      if (this.customerForm.get('phone').invalid) {
        // Handle phone number errors
        this._messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Veuillez entrer un numéro de téléphone valide.' });
        return;
      }
      this.showOTPForm();
      return;
    } else {
      this.customerForm.controls['phone'].setValue(this.customerFileService.customerFile.phone);
    }

    if (this.customerForm.invalid) {
      this._consoleService.debug('FormIdComponent - updateCustomer', 'r', 'customerForm', this.customerForm);
      // Handle form errors
      this._messageService.add({severity: 'error', summary: 'Erreur', detail: 'Veuillez remplir tous les champs obligatoires.', });
      return;
    }

    // Update customerFileService with form values
    this.customerFileService.customerFile = {
      ...this.customerFileService.customerFile,
      ...this.customerForm.value,
    };

    const customer = this.prepareCustomerDataForRequest();
    this._consoleService.debug( 'FormIdComponent - updateCustomer', 'b', 'customer', customer );

    this._apiService.post('customers/' + this.customerFileService.customerFile.customerId, customer).subscribe((data) => {
      this.processTrustNsign();
    });
  }

  processTrustNsign() {
    const data = {
      phone: this.customerForm.value.phone,
    };

    this._apiService.post('trust-and-sign-folder', data).subscribe((data) => {
      this._consoleService.debug('FormIdComponent - processTrustNsign', 'o', 'data', data);
      this.otpForm.reset();
      this.otpRequest = false;
      this._loadingService.hide();
      this.showNetheosFrame.emit(true);
      this.iframeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(
        data.data
      );
      localStorage.setItem('iframeUrl', data.data);
    });
  }

  showOTPForm() {
    this.countdownTime = this._settingService.getByKey("otp_duration");

    const dataToSend = {
      to: this.customerForm.value.phone,
    };

    this._apiService.post('send-otp', dataToSend).subscribe((data) => {
      this._consoleService.debug('FormIdComponent - updateCustomer', 'o', 'data', data);
      this.otpRequest = true;
      this._loadingService.hide();
      this.startCountdown();
    });
  }

  validateOtp() {
    if (this.otpForm.invalid) {
      // Handle form errors
      this._messageService.add({
        severity: 'error',
        summary: 'Erreur',
        detail: 'Veuillez entrer le code OTP.',
      });
      return;
    }

    const customer = this.prepareCustomerDataForRequest();

    customer['otp_code'] = this.otpForm.value.otp_code;
    customer['phone'] = this.customerForm.value.phone;

    this._apiService.post('verify-otp', customer).subscribe(
      (data) => {
        this._consoleService.debug('FormIdComponent - validateOtp', 'o', 'data', data);
        if (data.status === 'Success') {
          this.customerFileService.customerFile.phone = data.data.phone;
          this.customerFileService.customerFile.customerId = data.data.customer_id;
          this.customerFileService.customerFile.customerFileId = data.data.customer_file_id;
          this.showPhoneField = false;
          this.otpForm.reset();
          this.otpRequest = false;
          this.updateCustomer();
        } else {
          this._messageService.add({ severity: 'error', summary: 'Une erreur est survenue', detail: data.message, sticky: true });
          this._loadingService.hide();
        }
      },
      (error) => {
        this._consoleService.debug('FormIdComponent - validateOtp - error', 'o', 'error', error);
        this._loadingService.hide();
      }
    );
  }

  startCountdown() {
    this.intervalId = setInterval(() => {
      if (this.countdownTime > 0) {
        this.countdownTime--;
        this.updateCountdownDisplay();
      } else {
        clearInterval(this.intervalId); // Stop countdown when time is up
      }
    }, 1000);
  }

  updateCountdownDisplay() {
    const hours = Math.floor(this.countdownTime / 3600);
    const minutes = Math.floor((this.countdownTime % 3600) / 60);
    const seconds = this.countdownTime % 60;

    this.countdownDisplay = this.pad(hours) + ':' + this.pad(minutes) + ':' + this.pad(seconds);
  }

  pad(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }

  resendOtp() {
    this.countdownTime = this._settingService.getByKey("otp_duration");
    this.showOTPForm();
  }

  isFieldInvalid(field: string, formGroup: FormGroup = this.customerForm): boolean {
    const control = formGroup.get(field);
    return control && control.invalid && (control.dirty || control.touched);
  }
}

// Custom age validator
export function ageValidator(minAge: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null; // Required validator will handle empty value
    }
    const [day, month, year] = value.split('/');
    const birthDate = new Date(+year, +month - 1, +day);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age >= minAge ? null : { ageInvalid: true };
  };
}
